<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th.bordered {
        /* border: 1px solid #000000; */
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    td.no-border-flat {
        border-top: none;
        border-bottom: none;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    /* Set the font size to 12px for printing */
    @media print {
        header, footer, .print-button, #content-area * :not(.content-wrapper *) {
            display: none !important;
            visibility: hidden !important;
        }

        .router-view,
        .router-content {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

</style>

<template>
    <div>
        <div class="flex w-full">
            <img :src="logo" height="55" class="mr-5">
            <div class="title text-left">
                <div>
                    <strong>SDN-FRM-LOG.07-001</strong>
                </div>
                <div>
                    <strong>BERITA ACARA PEMUSNAHAN PRODUK</strong>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr style="white-space: nowrap">
                    <th class="py-0">Nomor GRR</th>
                    <th class="py-0" colspan="5">: {{ data.grr_code }}</th>
                </tr>
                <tr>
                    <th class="py-2" colspan="6">Berdasarkan "Laporan Pemusnahan Barang Rusak (LPBR)" yang telah disetujui oleh RLH atau "Surat Persetujuan Retur (SPR)" ynag telah disetujui oleh Principal.</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Nomor SPR</th>
                    <th class="py-0" colspan="5">: {{ data.sr_code }}</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Tanggal</th>
                    <th class="py-0" colspan="5">: {{ formatDate(data.date) }}</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Principal</th>
                    <th class="py-0" colspan="5">: {{ data.supplier_name }}</th>
                </tr>
                <tr>
                    <th class="py-2" colspan="6">Maka dengan ini kami laporkan pemusnahan produk <i>"Bad Stock"</i> yang telah dilakukan pada:</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Tanggal</th>
                    <th class="py-0" colspan="5">: {{ formatDate(data.date) }}</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Lokasi Pemusnahan</th>
                    <th class="py-0" colspan="5">: {{  }}</th>
                </tr>
                <tr style="white-space: nowrap">
                    <th class="py-0">Cabang</th>
                    <th class="py-0" colspan="5">: {{ data.warehouse.name }}</th>
                </tr>
                <tr>
                    <th class="py-2" colspan="6">Yang telah dilakukan oleh kedua belah pihak (<i>Operational</i> dan <i>Commercial</i>) dengan detail produk sebagai berikut:</th>
                </tr>
                <tr>
                    <th class="py-1 bordered text-center align-middle w-1/6">No</th>
                    <th class="py-1 bordered text-center align-middle w-1/6">Kode</th>
                    <th class="py-1 bordered text-center align-middle w-1/6">Nama Produk</th>
                    <th class="py-1 bordered text-center align-middle w-1/6">Qty</th>
                    <th class="py-1 bordered text-center align-middle w-1/6">Unit</th>
                    <th class="py-1 bordered text-center align-middle w-1/6">Keterangan</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(td, idx) in data.handover_lines" :key="idx">
                    <td class="py-1 px-2">{{ idx + 1 }}</td>
                    <td class="py-1 px-2">{{ td.item_unit.sku_code }}</td>
                    <td class="py-1 px-2">{{ td.item_unit.item_name }}</td>
                    <td class="py-1 px-2">{{ td.qty }}</td>
                    <td class="py-1 px-2">{{ td.item_unit.name }}</td>
                    <td class="py-1 px-2"></td>
                </tr>
                <tr>
                    <td class="py-1 text-center" colspan="3">Total (UOM)</td>
                    <td>{{ data.handover_lines.reduce((prev, curr) => prev + curr.qty, 0) }}</td>
                    <td class="py-1"></td>
                    <td class="py-1"></td>
                </tr>
            </tbody>
        </table>
        <table class="w-full mt-3">
            <thead>
                <tr>
                    <th class="bordered text-center">Dibuat Oleh,</th>
                    <th class="bordered text-center">Disetujui Oleh,</th>
                    <th class="bordered text-center" colspan="3">Diketahui Oleh,</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        Admin Gudang
                        <br/>
                        <br/>
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        WHS Supervisor
                        <br/>
                        <br/>
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BLC/OS
                        <br/>
                        <br/>
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BAM/BAS
                        <br/>
                        <br/>
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BM/OM
                        <br/>
                        <br/>
                    </td>
                </tr>
                <tr>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . . . . . . .)</td>
                </tr>
                <tr>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                </tr>
            </tbody>
        </table>
        <table class="w-1/2 mt-3">
            <thead>
                <tr>
                    <th class="bordered text-center" colspan="3">Disaksikan Oleh,</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="w-1/2 px-1 text-center no-border-flat">
                        Eksternal SDN
                    </td>
                    <td class="w-1/2 px-1 text-center no-border-flat">
                        Principal
                    </td>
                </tr>
                <tr>
                    <td class="w-1/2 px-1 py-3 text-center"><br/><br/>(. . . . . . . . . . . . . . .)</td>
                    <td class="w-1/2 px-1 py-3 text-center"><br/><br/>(. . . . . . . . . . . . . . .)</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import logo from "@/assets/images/logo/logo-sdn.png"
import moment from "moment"
export default {
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.print()
        }
    },
    data() {
        return {
            id: null,
            logo: logo,
            code: null,
            date: null,
            warehouse: {},
            srcWarehouseArea: {},
            destWarehouseArea: {},
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_code: null,
                    item_name: null,
                    batch: null,
                    batch_only: null,
                    exp_date: null,
                    qty: null,
                    unit: null,
                    qty_uom: 0,
                }
            ],
            rawTotalQty: 0,
            totalQty: null,
            data: {
                handover_lines: [],
                warehouse: {},
            },
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            const resp = await this.$http.get("api/wms/v1/wms/outbound/supplier-return/handover/"+this.id)
            if (resp.code == 200) {
                this.data = resp.data
            }
            this.$vs.loading.close();
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
    },
    computed: {
        formatDate: function () {
            return (val) => moment(val).format("YYYY-MM-DD")
        }
    },
}
</script>
